import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import './whyus.scss';
import { images } from '../../constants';

const CardObjW = (props) => {
    return (
        <div className= {props.className}>
            <img className="wu__img" src={props.image} alt={props.title} />
            <img className="wu__blur" src={images.bgBlur} alt="background blur" />
            <div>
                <h2>{props.title}</h2>
                <p>{props.info}</p>
            </div>
        </div>
    );
};

const Whyus = ({ whyUsData }) => {
    const { language } = useLanguage();

    return (
        <>
            {language === 'ar' ? (
                <div dir="rtl" className="Whyus section ar">
                    <h1> ما الذي يميزنا؟</h1>
                </div>
            ) : (
                <div className="Whyus section">
                    <h1>Why us</h1>
                </div>
            )}
            <div className="container">
            {/* <img className="CU__Eletwo" src={images.elethree}/> */}
                {language === 'ar' ? (
                    <div dir='rtl' className="CardHolder-wu">
                        {whyUsData.map(({ id, icon, description_ar, title_ar }) => (
                            <CardObjW
                                key={id}
                                title={title_ar}
                                image={icon}
                                info={description_ar}
                                className = "CardObj-wu ar"
                            />
                        ))}
                    </div>
                ) : (
                    <div className="CardHolder-wu">
                        {whyUsData.map(({ id, title, icon, description }) => (
                            <CardObjW
                                key={id}
                                title={title}
                                image={icon}
                                info={description}
                                className = "CardObj-wu"
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default Whyus;
