import React, {useEffect, useRef, useState } from 'react';
import Lottie from 'lottie-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLanguage } from '../../context/LanguageContext';
import { images } from '../../constants';
import './About.scss';
import timelineJSON from '../../assets/TimelineScroll.json';

const About = ({ timelineData, backgroundData, aboutSectionsData }) => {
    const [timelines, setTimelines] = useState([]);
    const [position, setPosition] = useState(0);
    const [direction, setDirection] = useState(1);
    const { language } = useLanguage();

    const timelineRef = useRef(null);

    const month_mapping = {
        "January": "يناير",
        "February": "فبراير",
        "March": "مارس",
        "April": "أبريل",
        "May": "مايو",
        "June": "يونيو",
        "July": "يوليو",
        "August": "أغسطس",
        "September": "سبتمبر",
        "October": "أكتوبر",
        "November": "نوفمبر",
        "December": "ديسمبر"
    };

    useEffect(() => {
        const formattedData = timelineData.map(entry => {
            const dateObj = new Date(entry.date);
            const monthName = dateObj.toLocaleString('default', { month: 'long' });
            const year = dateObj.getFullYear();
            const date_en = `${monthName} ${year}`;
            const date_ar = `${month_mapping[monthName]} ${year}`;

            return {
                date: date_en,
                date_ar: date_ar,
                description: entry.description,
                description_ar: entry.description_ar,
                dateObj
            };
        });

        // Sort data by date
        formattedData.sort((a, b) => a.dateObj - b.dateObj);

        // Remove the dateObj property after sorting
        formattedData.forEach(entry => delete entry.dateObj);

        // Adding blank entries at the beginning and end of the timeline data
        setTimelines([{ date: ' ', description: ' ', description_ar: '', date_ar: ' ' }, ...formattedData, { date: ' ', description: ' ', description_ar: '', date_ar: ' ' }]);
    }, [timelineData]);

    const handlePlayForward = () => {
        if (timelineRef.current) {
            timelineRef.current.setDirection(1);
            timelineRef.current.setSpeed(2.7);
            timelineRef.current.play();
        }
        setPosition(prevPosition => Math.min(prevPosition + 1, timelines.length - 1));
        setDirection(1);
    };

    const handlePlayReverse = () => {
        if (timelineRef.current) {
            timelineRef.current.setDirection(-1);
            timelineRef.current.setSpeed(2.7);
            timelineRef.current.goToAndStop(timelineRef.current.getDuration(true), true);
            timelineRef.current.play();
        }
        setPosition(prevPosition => Math.max(prevPosition - 1, 0));
        setDirection(-1);
    };

    const handleAnimationComplete = () => {
        timelineRef.current.goToAndStop(0);
    };

    const getTimelineEntry = (index) => {
        return index < timelines.length ? timelines[index] : { date: ' ', description: ' ', description_ar: '', date_ar: '' };
    };

    const positions1 = getTimelineEntry(position);
    const positions2 = getTimelineEntry(position + 1);
    const positions3 = getTimelineEntry(position + 2);

    return (
        <>
            <div className="aboutUs container section">
                <div className="Hero__AboutsecB">
                    <a className='Landingbtn' href="#About Us"><img src={images.arrowRight} /></a>
                    <div className="Hero__head">
                        {backgroundData.map((about_us, index) => (
                            language === 'ar' ?
                                <div dir='rtl' className='ar' key={index}>
                                    <h1>من نحن؟</h1>
                                    <p>{about_us.bullet_ar}</p>
                                </div>
                                :
                                <div key={index}>
                                    <h1>About Us</h1>
                                    <p>{about_us.bullet}</p>
                                </div>
                        ))}
                    </div>
                    <div className="mvv__collection">
                        {aboutSectionsData.map((section, index) => (
                            language === 'ar' ?
                                <div dir='rtl' key={index} className="mvv__solo ar">
                                    <img className="mvv__img" src={section.image} />
                                    <img className="mvv__blur" src={images.bgBlur} />
                                    <h2>{section.title_ar}</h2>
                                    <p>{section.content_ar}</p>
                                </div>
                                :
                                <div key={index} className="mvv__solo">
                                    <img className="mvv__img" src={section.image} />
                                    <img className="mvv__blur" src={images.bgBlur} />
                                    <h2>{section.title}</h2>
                                    <p>{section.content}</p>
                                </div>
                        ))}
                    </div>
                </div>

                {
                    language === 'ar' ?
                        <div dir='rtl' className='Timeline__title ar'>
                            <h1> إنجازات مركز سمو الشيخ ناصر للبحوث والتطوير في الذكاء الاصطناعي</h1>
                            <p>جدول زمني للإنجازات </p>
                        </div>
                        :
                        <div className='Timeline__title'>
                            <h1>NAIRDC's Journey Through Time</h1>
                            <p>A Timeline of Achievements on NAIRDC's Journey</p>
                        </div>
                }

                <div className="aboutUs__timeline">
                    <div className='Timeline__content'>
                        <button onClick={handlePlayReverse} disabled={position === 0}>
                            <img className={`timeline__up ${position === 0 ? 'disabled' : ''}`} src={images.arrowRight} alt="Reverse" />
                        </button>

                        <AnimatePresence custom={direction} mode='wait'>
                            {language === 'ar' ?
                                <motion.div
                                    initial={{ opacity: 0, x: direction > 0 ? 50 : -50 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: direction > 0 ? -50 : 50 }}
                                    transition={{ duration: 0.25 }}
                                    key={position}
                                    className='timeline__desc'>
                                    
                                    <div dir='rtl' className='timeline__date ar'>
                                        <h3>{positions1.date_ar}</h3>
                                        <h2>{positions2.date_ar}</h2>
                                        <h3>{positions3.date_ar}</h3>
                                    </div>
                                    <div className="timeline__Vid">
                                        <Lottie
                                            lottieRef={timelineRef}
                                            autoplay={false}
                                            loop={false}
                                            animationData={timelineJSON}
                                            onComplete={handleAnimationComplete}
                                        />
                                        </div>
                                        <div dir='rtl' className='timeline__info ar'>
                                        {positions2.description && (
                                            <>
                                                <h3>{positions2.date_ar}</h3>
                                                <h4>{positions2.description_ar}</h4>
                                            </>
                                        )}
                                    </div>
                                    
                                </motion.div>
                                :
                                <motion.div
                                    initial={{ opacity: 0, x: direction > 0 ? -50 : 50 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: direction > 0 ? 50 : -50 }}
                                    transition={{ duration: 0.25 }}
                                    key={position}
                                    className='timeline__desc'>
                                    <div className='timeline__date'>
                                        <h3>{positions1.date}</h3>
                                        <h2>{positions2.date}</h2>
                                        <h3>{positions3.date}</h3>
                                    </div>
                                    <div className="timeline__Vid">
                                        <Lottie
                                            lottieRef={timelineRef}
                                            autoplay={false}
                                            loop={false}
                                            animationData={timelineJSON}
                                            onComplete={handleAnimationComplete}
                                        />
                                    </div>
                                    <div className='timeline__info'>
                                        {positions2.description && (
                                            <>
                                                <h3>{positions2.date}</h3>
                                                <h4>{positions2.description}</h4>
                                            </>
                                        )}
                                    </div>
                                </motion.div>
                            }
                        </AnimatePresence>

                        <button onClick={handlePlayForward} disabled={position >= timelines.length - 3}>
                            <img className={`timeline__down ${position >= timelines.length - 3 ? 'disabled' : ''}`} src={images.arrowLeft} alt="Forward" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;
