import React from 'react';
import Slider from "react-slick";
import { useLanguage } from '../../context/LanguageContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './skills.scss';

const Skills = ({ skillsData }) => {
    const settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,
        rows: 3,
        pauseOnHover: true,
        responsive: [
        {
            breakpoint: 768,
            settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
            }
        },
        {
            breakpoint: 475,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 1
            }
        },
        {
            breakpoint: 350,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        }
        ]
    };

    const { language } = useLanguage();

    return (
        <div className='Skills container'>
            {language === 'ar' ? 
                <h1>مهاراتنا الفنية</h1>
                :
                <h1>Our Technical Skills</h1>
            }

            <div className="skills_collection ">
                <Slider {...settings}>
                    {skillsData.map(skill => (
                        <div key={skill.id}>
                            <img src={skill.logo} alt={skill.name} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Skills;
