import React, { useState, useEffect } from 'react';


import { motion } from 'framer-motion';
import { images } from '../../constants';

  
const MediaCards = () => {

  const MediaData = [
    {
      img: <img src={images.pVR} alt="placeholder"/>,
      title: "New VR Advancments",
      desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deleniti eaque veniam deserunt sint, numquam nulla eveniet ipsa quaerat, quis dicta corrupti necessitatibus impedit minima incidunt?",
      id: "1",
  
    },
    {
      img: <img src={images.uxui} />,
      title: "Dashboard Anywhere!",
      desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore est quisquam porro, fuga sequi libero at alias cumque eligendi eos incidunt nisi modi eius, consectetur sunt odio nulla, cupiditate voluptatem.",
      id: "2",
    },
    {
      img: <img src={images.locpin} />,
      title: "Enhanced RFID Tracking",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
      id: "3",
    },
    {
      img: <img src={images.custexp} />,
      title: "Improve your Customer Experience",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
      id: "4",
    },
    {
      img: <img src={images.chatbottool} />,
      title: "Interactive Chatbot",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
      id: "5",
    },
    {
      img: <img src={images.aibot} />,
      title: "Ai Enhancment",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
      id: "6",
    },
    {
        img: <img src={images.aibot} alt="placeholder"/>,
        title: "New AI Technologies",
        desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deleniti eaque veniam deserunt sint, numquam nulla eveniet ipsa quaerat, quis dicta corrupti necessitatibus impedit minima incidunt?",
        id: "7",
    
      },
      {
        img: <img src={images.chatbottool} />,
        title: "Latest Chatbot",
        desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore est quisquam porro, fuga sequi libero at alias cumque eligendi eos incidunt nisi modi eius, consectetur sunt odio nulla, cupiditate voluptatem.",
        id: "8",
      },
      {
        img: <img src={images.custexp} />,
        title: "Asset Tracking",
        desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
        id: "9",
      },
      {
        img: <img src={images.locpin} />,
        title: "Customer Experience",
        desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
        id: "10",
      },
      {
        img: <img src={images.pVR} />,
        title: "VR Experience",
        desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
        id: "11",
      },
      {
        img: <img src={images.uxui} />,
        title: "Modern Dashboard",
        desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
        id: "12",
      },   {
        img: <img src={images.locpin} />,
        title: "Asset Tracking",
        desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
        id: "13",
      },
      {
        img: <img src={images.custexp} />,
        title: "Customer Experience",
        desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
        id: "14",
      },
      {
        img: <img src={images.pVR} />,
        title: "VR Experience",
        desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
        id: "15",
      },
      {
        img: <img src={images.uxui} />,
        title: "Modern Dashboard",
        desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.",
        id: "16",
      },
  ];

// --------------------------------------------------------------------------------------------------------------------- //

  const [media, setMedia] = useState(null);
  

    
const loadAnim = {
hidden: {
opacity: 0,
y: "-5%"
},
show: {
opacity: 1,
y: "0%",
transition: {
duration: 0.25,
}}
}
  
    const Modal = ({ media, closeModal }) => {
  
        return (
          <div className="highlight-overlay" onClick={closeModal}>
            <motion.div
              initial={{
                scale: 1,
                opacity: 0,
                y: "-60%",
                x: "-50%",
              }}
              animate={{
                scale:  1,
                opacity: [0, 1],
                y: "-50%",
                x: "-50%",
              }}
              exit={{
                scale: 1,
                opacity: 0,
                y: "-60%",
                x: "-50%",
              }}
              
              className='highlight-content' onClick={(e) => e.stopPropagation()}>

              <div className="highlight-img"> {media.img}</div>

              <h1 className="highlight-title">{media.title}</h1>

              <p className="highlight-desc">{media.desc}</p>

            </motion.div>
          </div>
        );
    };
    
const mediaHighlight = (mediaContent) => {
  setMedia(mediaContent);
}
    
const closeModal = () => {
  setMedia(null);
}


const calculatePageSize = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 768) {
    return 1;
  } else if (screenWidth <= 1280) {
    return 2;
  } else if (screenWidth <= 1450) {
    return 3;
  } else {
    return 6;
  }
};
  
const [pageSize, setPageSize] = useState(calculatePageSize());

const [currentPage, setCurrentPage] = useState(0);

const totalPages = Math.ceil(MediaData.length / pageSize);

const startIndex = currentPage * pageSize;

const endIndex = Math.min(startIndex + pageSize, MediaData.length);

const currentMediaData = MediaData.slice(startIndex, endIndex);

const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
};

const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
};
  
useEffect(() => {
  const handleResize = () => {
    setPageSize(calculatePageSize());
    setCurrentPage(0);
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  
return (
<>
  <div  className='Gallery'>
      <img
      className={`arrow ${currentPage === 0 ? 'disabled' : ''}`}
      src={images.arrowLeft}
      alt="Left arrow"
      onClick={handlePrevPage}
      />

        <motion.ul
        variants={loadAnim}
        initial="hidden"
        animate="show"
        exit="exit"
        className="mcardHolder ">

            {currentMediaData.map((mediaItem) => (  
            <motion.li
            initial={{opacity: [0.8, 1], y: [-100, 0] }}
            animate={{opacity: 1, y: 0 }}
            exit={{ opacity: [ 1, 0.8], y: [ 0, -100] }}
            transition={{ duration: 0.8 }}
                className="mcard "
                key={mediaItem.id}
            onClick={() => mediaHighlight(mediaItem)}>
                <div className="mcardImg"> {mediaItem.img}</div>
                <div className='mcardBody '>
                <h1 className="mcardTitle">{mediaItem.title}</h1>
                  </div>
            </motion.li>
          )
        )
      }
       </motion.ul>
        <img
        className={`arrow ${currentPage === totalPages - 1 ? 'disabled' : ''}`}
        src={images.arrowRight}
        alt="Right arrow"
        onClick={handleNextPage}
        />
  </div>
{media && <Modal media={media} closeModal={closeModal} />}

</>

)
}

export default  MediaCards;