import React from 'react';
import { images } from '../../constants';
import Lottie from 'lottie-react';
import './Comingsoon.scss'

const Comingsoon = () => {
    return (
      <>
        <div className='nav_space' />
        <div className='container c-soon'>

          <h1>Coming Soon</h1>
          {/* <hr /> */}
        </div>
         
</>
  )
}

export default Comingsoon