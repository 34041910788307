import React from 'react'
import MediaCards from './MediaCards'
import NewsCards from './newsCards'
import { images } from '../../constants'
import './Media.scss';

const Media = () => {


  
    return (
  <div id="Media">
      <div className='nav_space' />
          <div className='section container'>
          <h1>Media</h1>
              <div>
  
              <MediaCards />
  
              </div>
          </div>
              <div className='container section'>
  
              <NewsCards />
  
              </div>
  </div>
    )
  }
  
  export default Media;