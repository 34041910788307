import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { images } from '../../constants';
import './navbar.scss';
import { useLanguage } from '../../context/LanguageContext';

const Navbar = () => {
  const { language, toggleLanguage } = useLanguage();

  const menuItems = {
    en: [
      { id: 'About Us', label: 'About Us' },
      { id: 'Skills', label: 'Skills' },
      { id: 'Services', label: 'Services' },
      { id: 'Partners', label: 'Partners' },
      { id: 'Success Stories', label: 'Success Stories' },
      { id: 'Why Us', label: 'Why Us' }
    ],
    ar: [
      { id: 'Why Us', label: 'ما الذي يميزنا؟ '},
      { id: 'Success Stories', label: 'قصص نجاحنا' },
      { id: 'Partners', label: 'شركاؤنا' },
      { id: 'Services', label: 'خدماتنا' },
      { id: 'Skills', label: 'مهاراتنا الفنية' },
      { id: 'About Us', label: 'من نحن؟ '}
    ]
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
    <a href="#Top"><img src={images.logoWhite} /> </a>
    {/* <Link to="/"> <a href="#home"><img src={images.logo} /> </a></Link> */}
      </div>
      <div className='navbar__right'>
        <ul className="app__navbar-links">
          {menuItems[language].map((item) => (
            <li key={item.id} className='ar'>
              <a href={`#${item.id}`}>{item.label}</a>
            </li>
          ))}
          <br />
        </ul>
        <div className={`${language === 'en' ? 'ar' : 'en'}`}>
          <button onClick={toggleLanguage} className='language__btn'>
            {language === 'en' ? 'العربية' : 'English'}
          </button>
        </div>
        <div className={`${language === 'en' ? 'en' : 'ar'}`}>
        <a href="#Contact Us"><button className="contact__btn ar"> {language === 'en' ? 'Contact' : 'تواصل'} </button> </a>
        </div>
        <div className="app__social-media">
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/nairdc/"><img src={images.linkedInIcon} alt="LinkedIn" /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/NAIRDC_BH/"><img src={images.instagramIcon} alt="Instagram" /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/nairdcbh/"><img src={images.xIcon} alt="Facebook" /></a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;