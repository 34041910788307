import React from 'react'
import { useState, useEffect, useRef } from 'react';
import './successstories.scss';
import Spline from '@splinetool/react-spline';
import { useLanguage } from '../../context/LanguageContext'



const Successstories = () => {
  const [splines, setSplines] = useState([]);
    const { language } = useLanguage();

  return (
    <div className='success section container2'>

      <h1>{language === 'ar' ? 'قصص نجاحنا' : 'Our Success Stories'}</h1>
      <div className="spline__container">
        
        {/* <Spline className="Spline_Screens" scene="https://prod.spline.design/kiY1SQSkdwO1Dzly/scene.splinecode" /> */}
        {language === 'ar' ?
        <Spline className="Spline_Screens" scene="https://prod.spline.design/x7dzNXf754LbrhVh/scene.splinecode" />  
        :
      <Spline className="Spline_Screens " scene="https://prod.spline.design/bjz0PNfUkDJ1xnAU/scene.splinecode" /> 
      }
        
        </div>
          </div>
  )
}

export default Successstories;