import React, { useState } from 'react'

import { motion } from 'framer-motion';
import { images } from '../../constants';





const NewsCards = () => {

  const NewsData = [
    {
    img: <img src={images.pVR} alt="placeholder"/>,
    title: "News #1",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deleniti eaque veniam deserunt sint, numquam nulla eveniet ipsa quaerat, quis dicta corrupti necessitatibus impedit minima incidunt?Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusa...",
    id: "1",
  
  },
  {
    img: <img src={images.uxui} />,
    title: "News #2",
    desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore est quisquam porro, fuga sequi libero at alias cumque eligendi eos incidunt nisi modi eius, consectetur sunt odio nulla, cupiditate voluptatemLorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolorectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus...",
    id: "2",
  },
  {
    img: <img src={images.locpin} />,
    title: "News #3",
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolos....",
    id: "3",
  },
  {
    img: <img src={images.custexp} />,
    title: "News #4",
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesents....",
    id: "4",
  },
  {
    img: <img src={images.chatbottool} />,
    title: "News #5",
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporius....",
    id: "5",
  },
  {
    img: <img src={images.aibot} />,
    title: "News #6",
    desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, delectus temporibus at enim in soluta praesentium minus.Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium officia quaerat unde, dus....",
    id: "6",
  },
  
  ];

  // ---------------------------------------------------------------------------------------------------------------- //

  const NModal = ({ news, closenModal }) => {
  
    return (
      <div className="nhighlight-overlay" onClick={closenModal}>
        <motion.div
          
          initial={{
            scale: 1,
            opacity: 0,
            y: "-60%",
            x: "-50%",
          }}

          animate={{
            scale:  1,
            opacity: [0, 1],
            y: "-50%",
            x: "-50%",
          }}

          className='nhighlight-content' onClick={(e) => e.stopPropagation()}>
          <div className="nhighlight-img"> {news.img}</div>
          <h1 className="nhighlight-title">{news.title}</h1>
          <p className="nhighlight-desc">{news.desc}</p>
        </motion.div>
      </div>
    );
  };

  const [news, setNews] = useState(null);
  
  const newsHighlight = (newsContent) => {
  
    setNews(newsContent);
  }
  
  const closenModal = () => {
  
    setNews(null);
  }

  const loadnAnim = {
    hidden: {
    opacity: 0,
    y: "-5%"
    },
    show: {
    opacity: 1,
    y: "0%",
    transition: {
    duration: 0.25,
      }
    }
  }

  return (
<>
    <div className='container section '>
    <h1> News </h1>
          <motion.ul
          variants={loadnAnim}
          initial="hidden"
          animate="show"
          className="ncardHolder ">

              {NewsData.map((news) =>
              <>
              <li className="ncard " key={news.id} onClick={() => newsHighlight(news)}>

              <div className="ncardImg "> {news.img}</div>
              <div className="ncardDesc">
              <h1 className="ncardTitle">{news.title}</h1>
                      <p className="ncardBody">{news.desc} <strong>Read More</strong></p>
              </div>
              </li>
          </>
          )}
          </motion.ul>
    {news && <NModal news={news} closenModal={closenModal}/>}

    </div>
</>
  )
}

export default NewsCards;