import React from 'react'

const Error = () => {
  return (
<>
            <div className='navbar_space' />
            <div className='pholder-h'></div>
</>
  )
}

export default Error;