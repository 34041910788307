export const fetchData = async () => {
    try {
        const landingPageResponse = await fetch('/api/landing-page/');
        const landingPages = await landingPageResponse.json();

        const whyUsResponse = await fetch('/api/why_us/');
        const whyUsData = await whyUsResponse.json();

        const timelineResponse = await fetch('/api/timeline/');
        const timelineData = await timelineResponse.json();

        const backgroundResponse = await fetch('/api/background/');
        const backgroundData = await backgroundResponse.json();

        const aboutSectionsResponse = await fetch('/api/about-sections/');
        const aboutSectionsData = await aboutSectionsResponse.json();

        const skillsResponse = await fetch('/api/skills/');
        const skillsData = await skillsResponse.json();

        const servicesResponse = await fetch('/api/services/');
        const servicesData = await servicesResponse.json();

        const partnersResponse = await fetch('/api/partners/');
        const partnersData = await partnersResponse.json();

        const contactInfoResponse = await fetch('/api/info/');
        const contactInfoData = await contactInfoResponse.json();

        return {
            landingPages,
            whyUsData,
            timelineData,
            backgroundData,
            aboutSectionsData,
            skillsData,
            servicesData,
            partnersData,
            contactInfoData,
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
