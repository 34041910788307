import React from 'react';
import './partners.scss';
import { useLanguage } from '../../context/LanguageContext';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { images } from '../../constants';

const Partners = ({ partnersData }) => {
    const { language } = useLanguage();

    const settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 475,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="partners container">
            <div className="partners__collection">
                {language === 'ar' ? 
                    <h1>شركاؤنا</h1>
                    :
                    <h1>Partners</h1>
                }
                <img className="p__eleone" src={images.elethree} alt="Element" />
                <div className="partners__row1">
                    <Slider {...settings}>
                        {partnersData.map(partner => (
                            <div key={partner.id}>
                                <img src={partner.logo} alt={`${partner.name} logo`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Partners;
