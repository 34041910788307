import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useLanguage } from '../../context/LanguageContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Hero.scss';
import { images } from '../../constants';

const Hero = ({ landingPages }) => {
    const { language } = useLanguage();
    const [videoSrc, setVideoSrc] = useState('');
    const [loaded, setLoaded] = useState(false);

    const settings = {
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 13000,
    };

    useEffect(() => {
        if (landingPages.length > 0) {
            const src = language === 'ar' ? landingPages[0].animation_ar : landingPages[0].animation;
            setVideoSrc(src);
        }
    }, [landingPages, language]);

    const handleVideoLoad = () => {
        setLoaded(true);
    };

    useEffect(() => {
        setLoaded(false);
    }, [videoSrc]);

    return (
        <div className="container2">
            <div className="LandingVid">
                <Slider {...settings}>
                    {landingPages.map((page, index) => (
                        <div key={index} className="video-wrapper">
                            <video
                                className={`video-element ${loaded ? 'loaded' : ''}`}
                                src={language === 'ar' ? page.animation_ar : page.animation}
                                autoPlay
                                loop
                                muted
                                onLoadedData={handleVideoLoad}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Hero;
