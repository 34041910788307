import React from 'react'
import { images } from '../../constants';

import './Contact.scss';
import '../../container/ContactUs/contactus.scss';

const Contact = () => {
    return (
        <>
            <div className='nav_space' />

            <div className='Contact__title'>
            <h1> Contact us </h1>
            </div>

            <div className='Contact__collection container'>
                {/* Location */}
                <div className="Contact__infocollection">
                    <div className="Contact__info-section">
                        <img className="Contact__img" src={images.location} />
                        <img className="Contact__blur" src={images.bgBlur} />
                        <h2>Visit Us</h2>
                        <p>Road No. 5712, Jaww, Bahrain</p>
                    </div>
                    {/* Email Address */}
                    <div className="Contact__info-section">
                        <img className="Contact__img" src={images.mail} />
                        <img className="Contact__blur" src={images.bgBlur} />
                        <h2>Send an Email</h2>
                        <p>info@nairdc.bh</p>
                    </div>
                    {/* Phone Number */}
                    <div className="Contact__info-section">
                        <img className="Contact__img" src={images.phone} />
                        <img className="Contact__blur" src={images.bgBlur} />
                        <h2>Contact Us</h2>
                        <p>+973 1711 1171</p>
                    </div>
                </div>
                {/* Message Form */}
                <div className="Contact__form">
                    <form>
                        <h2> Leave a Message!</h2>
                        <input className="Contact__name" type="text" placeholder="Full name" />
                        <input className="Contact__no" type="number" placeholder="Phone number" />
                        <input className="Contact__email" type="email" placeholder="Email Address" />
                        <textarea className="Contact__msg" type="text" placeholder="Tell us about your project." />
                    </form>

                    <button className="Contact__send">
                        Send
                        <img src={images.arrowRight} />

                    </button>
                </div>
            </div>

            <div className='section container'>

                <div className='contact_map'>

                </div>

            </div>

        </>

    );
};

export default Contact;