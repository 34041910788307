import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Navbar, Footer } from './container';
import { Home, Media, Contact, Comingsoon, Error,Loading } from './pages';
import { LanguageProvider } from './context/LanguageContext';
import './App.scss';
import { fetchData } from './fetchData'; // Import the data fetching function



const App = () => {
    const [loading, setLoading] = useState(true); // Global loading state
    const [appData, setAppData] = useState(null); // State to hold fetched data

    useEffect(() => {
        const loadAppData = async () => {
            try {
                const data = await fetchData();
                setAppData(data); // Store the fetched data
                setLoading(false); // Set loading to false after data is loaded
            } catch (error) {
                console.error('Error loading app data:', error);
                setLoading(false);
            }
        };

        loadAppData();
    }, []);

    if (loading) {
        return <Loading />; // Display loading spinner if data is still being fetched
    }

    return (
<>
<LanguageProvider>
<Router>

        <div id="home" className="App">
                    
                    <Navbar />
                    <Suspense fallback={<Loading />}>
                        <Routes>
                            <Route exact path="/" element={<Home appData={appData} />} />
                            <Route path="/media" element={<Media />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/coming-soon" element={<Comingsoon />} />
                            <Route path="/error" element={<Error />} />
                        </Routes>
                    </Suspense>
                <Footer contactInfoData={appData.contactInfoData} />
                    
        </div>
        
</Router>  
</LanguageProvider>      

</>
    );
}

export default App;