// Logos
import logo from '../assets/logo.png';
import logoWhite from '../assets/logo-white.png';

import logo3d from '../assets/logo-3d.png';
import nairdccmpct from '../assets/N.png';
import instagramIcon from '../assets/instagram-svgrepo-com.svg';
import linkedInIcon from '../assets/linkedin-svgrepo-com.svg';
import xIcon from '../assets/X_logo_2023.svg';
import nairdc3d from '../assets/Nairdc3d.png';
import homesvg from '../assets/Homepage.svg';

// Misc
import arrowLeft from '../assets/arrow-left.png';
import arrowRight from '../assets/arrow-right.png';
import eleone from '../assets/Element-2.png';
import eletwo from '../assets/Element-1.png';
import elethree from '../assets/Circle png.png';
import elefour from '../assets/BG-Element-2.png';
import elefive from '../assets/BG-Element-3.png';
import elesix from '../assets/BG-Element-6.png';
import one from '../assets/one-icon.png';
import two from '../assets/two-icon.png';
import three from '../assets/three-icon.png';
import four from '../assets/four-icon.png';
import five from '../assets/five-icon.png';
import bgBlur from "../assets/blur-bg.png";
import circleIcon from '../assets/circle-icon.png';
import aisvg from '../assets/aisvg.svg';
import comingSoon from '../assets/coming-soon.png';
import landing1 from '../assets/Main Banner.mp4';
import landing2 from '../assets/Chatbot Banner2.mp4';
import landing3 from '../assets/Dashboards Banner.mp4';
import landing1AR from '../assets/Main Banner (AR).mp4';
import landing2AR from '../assets/Chatbot Banner2 (AR).mp4';
import landing3AR from '../assets/Dashboards Banner (AR).mp4';
import pedestal from '../assets/pedestal.png';

import values from '../assets/values-icon.png';
import vision from '../assets/vision-icon.png';
import mission from '../assets/Mission 2.png';
import location from '../assets/location-icon.png';
import phone from '../assets/phone-icon.png';
import mail from '../assets/mail-icon.png';

import awardWinning from '../assets/award-winning-icon.png';
import bahrainIcon from '../assets/bahrain-icon.png';

//Partners
import alba from '../assets/01Alba.png';
import asry from '../assets/04ASRY.png';
import BAS from '../assets/BAS.png';
import BapcoG from '../assets/Bapco Gas.png';
import BapcoR from '../assets/Bapco Refining.png';
import benefit from '../assets/06Benefit.png';
import CBB from '../assets/CBB.png';
import CSB from '../assets/CSB.png';
import edb from '../assets/08EDB.png';
import GDTraffic from '../assets/Traffic.png';
import GovHospitals from '../assets/Gov Hospitals.png';
import IGA from '../assets/IGA.png';
import MUN from '../assets/MUN.png';
import MOIC from '../assets/MOIC.png';
import MOI from '../assets/MOI.png';
import MOW from '../assets/MOW.png';
import NAO from '../assets/NAO.png';
import Shura from '../assets/Shura Council.png';
import SLRB from '../assets/SLRB.png';
import Tamkeen from '../assets/Tamkeen.png';
import YBAKanoo from '../assets/YBA Kanoo.png';
import zain from '../assets/16Zain.png';

//Services

//Skills
import skillscss from '../assets/css.svg';
import skillsdjango from '../assets/django.svg';
import skillsjquery from '../assets/jquery.svg';
import skillsflutter from '../assets/flutter.svg';
import skillshtml from '../assets/html.svg';
import skillsjs from '../assets/js.svg';
import skillsjupyter from '../assets/jupyter.svg';
import skillskeras from '../assets/keras.svg';
import skillslangchain from '../assets/langchain.png';
import skillsmatplotlib from '../assets/matplotlib.svg';
import skillsnumpy from '../assets/numpy.svg';
import skillsopencv from '../assets/opencv.svg';
import skillssql from '../assets/sql.svg';
import skillspandas from '../assets/pandas.svg';
import skillsplotly from '../assets/skills plotly.png';
import skillspowerbi from '../assets/power bi.svg';
import skillspython from '../assets/python.svg';
import skillspytorch from '../assets/pytorch.svg';
import skillsreact from '../assets/react.svg';
import skillsscikit from '../assets/scikit.svg';
import skillstensor from '../assets/tensorflow.svg';
import skillsvue from '../assets/vue.svg';
import skillsseaborn from '../assets/seaborn.svg';

// 3d imgs
import dataAnalytics from '../assets/blockchain-icon.png';
import chatBot from '../assets/chatbot-icon.png';
import compCost from '../assets/competitive-costs-icon.png';
import computerVision from '../assets/computer-vision-icon.png';
import customization from '../assets/customization-icon.png';
import blockchain from '../assets/data-analytics-icon.png';
import iot from '../assets/iot-icon.png';
import ocr from '../assets/ocr-icon.png';
import predictionForecasting from '../assets/prediction-forecasting-icon.png';
import dashboard from '../assets/real-time-dashboard-icon.png';
import recommendationSystem from '../assets/recommendation-system-icon.png';
import rfid from '../assets/rfid-icon.png';
import roboticSolutions from '../assets/robotic-solutions-icon.png';
import rpa from '../assets/rpa-icon.png';
import support from '../assets/support-icon.png';

//Videos
import chatbotVid from "../assets/Chatbot.mp4";
import chatbotVidL from "../assets/Chatbot Animated.mp4";
import blockchainVid from '../assets/LLM Icon.mp4';
import computerVisionVid from '../assets/Computer-Vision.mp4'
import dashboardVid from '../assets/Dashboard.mp4';
import dataAnalyticsVid from '../assets/ML Icon.mp4'
import iotVid from '../assets/ERP Icon.mp4';
import ocrVid from '../assets/GenAI Icon.mp4'
import predictionandforecastingVid from '../assets/Prediction-and-Forecasting.mp4';
import recosystemVid from '../assets/Recommendation-System.mp4';
import rfidVid from '../assets/RFID.mp4';
import robosysVid from '../assets/Robotic-Solutions.mp4';
import rpaVid from '../assets/RPA.mp4';

//Media
import pVR from '../assets/vrimage.jpeg';
import uxui from '../assets/uxui.jpg';
import locpin from '../assets/locationpin.jpg';
import custexp from '../assets/customerexp.jpg';
import chatbottool from '../assets/chatbottool.jpg';
import aibot from '../assets/3dbot.avif';


export default {

    logo,logoWhite, nairdccmpct, logo3d, homesvg, nairdc3d,
    
    arrowLeft, arrowRight, one, two, three, five, four, bgBlur, circleIcon, comingSoon, aisvg, landing1, landing2, landing3, landing1AR, landing2AR, landing3AR, pedestal,
    
    awardWinning, bahrainIcon, support, location, mail, mission, values, vision,
    
    blockchain, chatBot, compCost, computerVision, customization, dataAnalytics, iot, ocr, phone, predictionForecasting, dashboard, recommendationSystem, rfid, roboticSolutions, rpa,
    
    chatbotVid, blockchainVid, computerVisionVid, dashboardVid, dataAnalyticsVid, iotVid, ocrVid, predictionandforecastingVid, recosystemVid, rfidVid, robosysVid, rpaVid,
    
    alba, asry, BAS, BapcoG, BapcoR, benefit, CBB, CSB, edb, GDTraffic, GovHospitals, IGA, MUN, MOIC, MOI, MOW, NAO, Shura, SLRB, Tamkeen, YBAKanoo, zain,
    
    skillscss, skillsdjango, skillsjquery, skillsflutter, skillshtml, skillsjs, skillsjupyter, skillskeras, skillslangchain, skillsmatplotlib, skillsnumpy, skillsopencv, skillssql, skillspandas, skillsplotly, skillspowerbi, skillspython, skillspytorch, skillsreact, skillsscikit, skillstensor, skillsvue, skillsseaborn,
    
    chatbotVidL, eleone, eletwo, elethree, elefour, elefive, elesix, instagramIcon, linkedInIcon, xIcon,

    pVR, uxui, locpin, custexp, chatbottool, aibot, 
};