import React from 'react';

import { createRoot } from "react-dom/client";
import App from './App';
import './index.css';

const rootElement = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

createRoot(document.getElementById('root')).render(rootElement);